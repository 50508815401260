.sticky-wrapper {
  position: relative;
  min-height: 4rem;
}

.sticky .navbar-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
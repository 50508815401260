@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFD;

  -ms-overflow-style: none;  /* disable scrollbar IE and Edge */
  scrollbar-width: none;  /* disable scrollbar Firefox */
}

/* disable scrollbar chrome */
::-webkit-scrollbar {
  display: none;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: #759EFF;
}

::selection {
  color: white;
  background: #759EFF;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap);
.grid-link:hover{
  opacity: 0.6;
}
.infoa-box{
  align-content: center;
  display: flex;
}

.info-grid{
  margin: 1rem;
}

@media (max-width: 960px) {
  .info-box{
    max-width: 25rem;
  }
}

@media (max-width: 600px) {
  .info-box{
    max-width: 20rem;
  }
}
.about-page{
  min-height: 100vh;
  background-color: #F6F6FD;
  padding:2rem;
}

.text-title{
  color: #9B9B9B;
}

.text-emphasis{
  color: #759EFF;
}

.profile-img {
  max-width: 20rem;
  margin: 2rem;
  border-radius: 50%;
  box-shadow: 0.175rem 0.175rem 1rem 0 #9B9B9B;
}
.work-page{
  min-height: 100vh;
  width: 100vw;
  padding:2rem;
  background-color: #EEEEF5;
}

.text-title{
  color: #9B9B9B;
}
.info-box{
  box-shadow: 0.175rem 0.175rem 1rem 0 #9B9B9B;
  border-radius: 0.7rem;
  min-height: 10rem;
  width: 50rem;
  display: flex;
  align-items: center;
  justify-items: center;
}

.text-emphasis{
  color: #759EFF;
}

.text-under{
  color: #9B9B9B;
}

.company-c-logo{
  width: 90%;
  height: auto;
  margin: auto 1rem;
}

.grid-work-info{
  padding:0.5rem;
}

button{
  padding: 0;
  margin: 0;
  border: 0;
  background-color: inherit;
  outline: none;
}

button:active {
  opacity: 0.6;
}

button:hover {
  opacity: 0.6;
}

@media (max-width: 960px) {
  .company-c-logo{
    width: 15rem;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 600px) {
  .company-c-logo{
    width: 15rem;
    margin-bottom: 0.5rem;
  }
}
.info-box{
  box-shadow: 0.175rem 0.175rem 1rem 0 #9B9B9B;
  border-radius: 0.7rem;
  min-height: 10rem;
  width: 50rem;
  display: flex;
  align-items: center;
  justify-items: center;
}

.text-emphasis{
  color: #759EFF;
}

.text-under{
  color: #9B9B9B;
}

.grid-work-info{
  padding:0.5rem;
}

button{
  padding: 0;
  margin: 0;
  border: 0;
  background-color: inherit;
  outline: none;
}

.company-ci-logo{
  width: 90%;
  height: auto;
  margin: auto 1rem;
}

button:active {
  opacity: 0.6;
}

button:hover {
  opacity: 0.6  ;
}

@media (max-width: 960px) {
  .company-ci-logo{
    width: 15rem;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 600px) {
  .company-ci-logo{
    width: 15rem;
    margin-bottom: 0.5rem;
  }
}
.project-f-box{
  box-shadow: 0.175rem 0.175rem 1rem 0 #9B9B9B;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  width: 25rem;
}

.text-emphasis{
  color: #759EFF;
}

.links{
  display: flex;
}

.text-under{
  color: #9B9B9B;
}

.project-title{
  margin-bottom: 0.5rem;
}

.project-logo{
  width:24.8rem;
  border:0.1rem solid #759EFF;
  border-radius: 0.7rem;
  background-color:none;
  margin-top: 0.5rem;
}

button{
  padding: 0;
  margin: 0;
  border: 0;
  background-color: inherit;
  outline: none;
}

button:active {
  opacity: 0.6;
}

button:hover {
  opacity: 0.6;
}

@media (max-width: 960px) {
  .project-logo{
    width:24.8rem;
  }
}

@media (max-width: 600px) {
  .project-logo{
    width:19.8rem;  
  }
}
.project-f-box{
  box-shadow: 0.175rem 0.175rem 1rem 0 #9B9B9B;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  width: 25rem;
}

.text-emphasis{
  color: #759EFF;
}

.links{
  display: flex;
}

.text-under{
  color: #9B9B9B;
}

.project-title{
  margin-bottom: 0.5rem;
}

.project-logo{
  width:24.8rem;
  border:0.1rem solid #759EFF;
  border-radius: 0.7rem;
  background-color:none;
  margin-top: 0.5rem;
}

button{
  padding: 0;
  margin: 0;
  border: 0;
  background-color: inherit;
  outline: none;
}

button:active {
  opacity: 0.6;
}

button:hover {
  opacity: 0.6;
}

@media (max-width: 960px) {
  .project-logo{
    width:24.8rem;
  }
}

@media (max-width: 600px) {
  .project-logo{
    width:19.8rem;  
  }
}
.project-page{
  min-height: 100vh;
  width: 100vw;
  padding:2rem;
  background-color: #E5E5EE;
}
.sticky-wrapper {
  position: relative;
  min-height: 4rem;
}

.sticky .navbar-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFD;

  -ms-overflow-style: none;  /* disable scrollbar IE and Edge */
  scrollbar-width: none;  /* disable scrollbar Firefox */
}

/* disable scrollbar chrome */
::-webkit-scrollbar {
  display: none;
}

::selection {
  color: white;
  background: #759EFF;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nft-grid {
  min-height: 100vh;
  width: 100vw;
  padding:2rem;
}

.slide1 {
  background-color: #e3dae7;
}
.slide2 {
  background-color: #dacedf;
}
.slide3 {
  background-color: #d0c1d7;
}
.slide4 {
  background-color: #c7b4cf;
}
.slide5 {
  background-color: #bea8c7;
}


.comic-sans {
  font-family: 'Comic Sans MS' !important;
} 
@media (max-width: 600px) {
  .comic-sans{
    font-size: larger !important;
  }
  .comic-sans.collab{
    font-size: medium !important;
  }
  .comic-sans.names{
    font-size: small !important;
  }
}

